import { Flex, Text } from "@project/ui";
import { useLiveTickerQuery } from "@/components/contentful/live-ticker/__generated/live-ticker.contentful.generated";
import { useAppStore } from "@/store/app-store";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Marquee from "react-fast-marquee";
// eslint-disable-next-line no-restricted-imports
import { chakra } from "@chakra-ui/react";

type LiveTickerJSONItem = {
    id: string;
    value: string;
    time: string;
};

export type LiveTickerProps = {
    mainNavigationId: string;
};

const ChakraMarquee = chakra(Marquee);

export const LiveTicker = (props: LiveTickerProps) => {
    const { mainNavigationId } = props;

    const { locale, isPreview } = useRouter();

    const { data } = useLiveTickerQuery(
        {
            id: mainNavigationId,
            locale: locale!,
            preview: Boolean(isPreview),
        },
        {
            refetchOnWindowFocus: true,
            // Refetch interval is set to 30 seconds in production and 5 seconds in development
            refetchInterval: process.env.NODE_ENV === "production" ? 30000 : 5000,
            // Rerender any children when the query results change
            notifyOnChangeProps: "all",
            // Disable structural sharing, so we can compare the data object with the previous one inside a useEffect
            // https://tkdodo.eu/blog/react-query-render-optimizations#structural-sharing
            structuralSharing: false,
        }
    );
    const { dispatch } = useAppStore();

    const [liveTickerItems, setLiveTickerItems] = useState<LiveTickerJSONItem[]>([]);

    useEffect(() => {
        dispatch({
            type: "SET_LIVE_TICKER",
            payload:
                !!data &&
                Array.isArray(data?.mainNavigation?.liveTicker) &&
                data?.mainNavigation.liveTicker.length > 0,
        });

        if (data?.mainNavigation?.liveTicker) {
            setLiveTickerItems((prevItems) => {
                const newItems = data?.mainNavigation?.liveTicker;
                const mergedItems = [...prevItems];

                // Remove items that are not in the new data
                const currentIds = new Set(newItems.map((item: LiveTickerJSONItem) => item.id));
                const filteredItems = mergedItems.filter((item) => currentIds.has(item.id));

                // Add new items that are not in the previous state
                newItems.forEach((newItem: LiveTickerJSONItem) => {
                    if (!filteredItems.find((item) => item.id === newItem.id)) {
                        filteredItems.push(newItem);
                    } else if (filteredItems.find((item) => item.id === newItem.id)) {
                        const index = filteredItems.findIndex((item) => item.id === newItem.id);
                        filteredItems[index] = newItem;
                    }
                });

                return filteredItems;
            });
        }
    }, [data, dispatch]);

    if (!data?.mainNavigation?.liveTicker || data.mainNavigation.liveTicker.length < 1) {
        return null;
    }

    return (
        <Flex
            className="live-ticker"
            alignItems="center"
            width="full"
            bg="porscheBlack"
            color="allWhite"
            h="liveTickerHeight"
            overflow="hidden"
            position="relative"
        >
            <ChakraMarquee autoFill pauseOnHover>
                {liveTickerItems.map((item: LiveTickerJSONItem) => (
                    <Text key={item.id} size="x-small" mx=".5ch">
                        {item.value}
                    </Text>
                ))}
            </ChakraMarquee>
        </Flex>
    );
};
