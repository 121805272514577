import * as Types from "../../../../lib/contentful/__generated/graphql.types";

import {
    ComponentReferenceFields_Car_Fragment,
    ComponentReferenceFields_ContentTag_Fragment,
    ComponentReferenceFields_Driver_Fragment,
    ComponentReferenceFields_ExternalLink_Fragment,
    ComponentReferenceFields_Footer_Fragment,
    ComponentReferenceFields_LanguageSelectorItem_Fragment,
    ComponentReferenceFields_MainNavigation_Fragment,
    ComponentReferenceFields_Microcopy_Fragment,
    ComponentReferenceFields_MicrocopySet_Fragment,
    ComponentReferenceFields_ModalLink_Fragment,
    ComponentReferenceFields_ModuleAccordion_Fragment,
    ComponentReferenceFields_ModuleAudioPlayer_Fragment,
    ComponentReferenceFields_ModuleCarTechSpecs_Fragment,
    ComponentReferenceFields_ModuleCarousel_Fragment,
    ComponentReferenceFields_ModuleCircuit_Fragment,
    ComponentReferenceFields_ModuleDriverList_Fragment,
    ComponentReferenceFields_ModuleGallery_Fragment,
    ComponentReferenceFields_ModuleIframe_Fragment,
    ComponentReferenceFields_ModuleImage_Fragment,
    ComponentReferenceFields_ModuleListenToTheEngine_Fragment,
    ComponentReferenceFields_ModuleMediaFeature_Fragment,
    ComponentReferenceFields_ModulePageLinkTile_Fragment,
    ComponentReferenceFields_ModuleQuote_Fragment,
    ComponentReferenceFields_ModuleRichText_Fragment,
    ComponentReferenceFields_ModuleSideBySide_Fragment,
    ComponentReferenceFields_ModuleSpacer_Fragment,
    ComponentReferenceFields_ModuleSplitLayout_Fragment,
    ComponentReferenceFields_ModuleTable_Fragment,
    ComponentReferenceFields_ModuleVideo_Fragment,
    ComponentReferenceFields_Navigation_Fragment,
    ComponentReferenceFields_PageArticle_Fragment,
    ComponentReferenceFields_PageBasic_Fragment,
    ComponentReferenceFields_PageCar_Fragment,
    ComponentReferenceFields_PageCategory_Fragment,
    ComponentReferenceFields_PageDriver_Fragment,
    ComponentReferenceFields_PageHomepage_Fragment,
    ComponentReferenceFields_PageRaceEvent_Fragment,
    ComponentReferenceFields_PageRaceSeries_Fragment,
    ComponentReferenceFields_PageSearch_Fragment,
    ComponentReferenceFields_PageTeam_Fragment,
    ComponentReferenceFields_PartAccordionItem_Fragment,
    ComponentReferenceFields_PartCircuitHotspot_Fragment,
    ComponentReferenceFields_PartCircuitTimelineItem_Fragment,
    ComponentReferenceFields_PartMainNavigationAccordion_Fragment,
    ComponentReferenceFields_PartMainNavigationItem_Fragment,
    ComponentReferenceFields_Partner_Fragment,
    ComponentReferenceFields_PartnerSet_Fragment,
    ComponentReferenceFields_SeoMetadata_Fragment,
    ComponentReferenceFields_SiteSettings_Fragment,
    ComponentReferenceFields_Team_Fragment,
} from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { ComponentReferenceFieldsFragmentDoc } from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { customFetcher } from "@/lib/contentful/fetch-config";
export type LiveTickerFieldsFragment = {
    __typename?: "MainNavigation";
    liveTicker?: any | null;
} & ComponentReferenceFields_MainNavigation_Fragment;

export type LiveTickerQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview: Types.Scalars["Boolean"]["input"];
    id: Types.Scalars["String"]["input"];
}>;

export type LiveTickerQuery = {
    __typename?: "Query";
    mainNavigation?: ({ __typename?: "MainNavigation" } & LiveTickerFieldsFragment) | null;
};

export const LiveTickerFieldsFragmentDoc = `
    fragment LiveTickerFields on MainNavigation {
  ...ComponentReferenceFields
  liveTicker
}
    `;
export const LiveTickerDocument = `
    query LiveTicker($locale: String!, $preview: Boolean!, $id: String!) {
  mainNavigation(id: $id, locale: $locale, preview: $preview) {
    ...LiveTickerFields
  }
}
    ${LiveTickerFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}`;

export const useLiveTickerQuery = <TData = LiveTickerQuery, TError = unknown>(
    variables: LiveTickerQueryVariables,
    options?: Omit<UseQueryOptions<LiveTickerQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<LiveTickerQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<LiveTickerQuery, TError, TData>({
        queryKey: ["LiveTicker", variables],
        queryFn: customFetcher<LiveTickerQuery, LiveTickerQueryVariables>(
            LiveTickerDocument,
            variables
        ),
        ...options,
    });
};

useLiveTickerQuery.getKey = (variables: LiveTickerQueryVariables) => ["LiveTicker", variables];

useLiveTickerQuery.fetcher = (
    variables: LiveTickerQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<LiveTickerQuery, LiveTickerQueryVariables>(
        LiveTickerDocument,
        variables,
        options
    );
